export default function useI18nSearchAvailabilities() {
  /********************
   * COMPOSITIONS      *
   ********************/
  const searchStore = useSearchStore();
  const { $gettext, interpolate, $pgettext } = useGettext();

  /********************
   * REFS & VARS       *
   ********************/
  const i18nLabelPitch = $gettext('Pitch');

  const i18nLabelRentals = $gettext('Rental accommodation');

  const i18nFlexHeadline = $gettext('Flexible travel dates:');

  const i18nResetSelection = $gettext('Reset selection');

  const i18nFlexDayOne = $gettext('± 1 Days');

  const i18nFlexDayTwo = $gettext('± 2 Days');

  const i18nFlexDayThree = $gettext('± 3 Days');

  const i18nBenefitCardHeadline = $gettext('Benefit card:');

  const i18nAccommodationChoice = computed(() => {
    if (searchStore.searchParams.acco_type === 'pitch') {
      return i18nLabelPitch;
    }
    if (searchStore.searchParams.acco_type === 'acco') {
      return i18nLabelRentals;
    }

    return $gettext('All accommodation types');
  });

  const i18nPlaceholderDate = $gettext('Select date');

  const i18nPlaceholderLocation = $gettext('Country, region, campsite');

  const i18nLocationEurope = $gettext('Europe');

  const i18nLocationArea = $gettext('In the map section');

  /********************
   * FUNCTIONS         *
   ********************/
  function i18nNoExactAvailabilities(from, until) {
    const translated = $pgettext(
      'Campsite Tile - no exact availability match',
      'For your exact travel period %{from} - %{until} this campsite has no more availabilities. However, there is still availability for similar travel periods.',
    );
    return interpolate(translated, { from, until });
  }

  return {
    i18nAccommodationChoice,
    i18nBenefitCardHeadline,
    i18nFlexDayOne,
    i18nFlexDayThree,
    i18nFlexDayTwo,
    i18nFlexHeadline,
    i18nLabelPitch,
    i18nLabelRentals,
    i18nLocationArea,
    i18nLocationEurope,
    i18nNoExactAvailabilities,
    i18nPlaceholderDate,
    i18nPlaceholderLocation,
    i18nResetSelection,
  };
}
